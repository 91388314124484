import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid bg-white" }
const _hoisted_2 = {
  class: "wrapper d-flex flex-column flex-row-fluid pt-2",
  id: "kt_wrapper"
}
const _hoisted_3 = { class: "d-flex flex-column flex-column-fluid pb-5" }
const _hoisted_4 = {
  class: "content fs-6 d-flex flex-column-fluid",
  id: "kt_content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_KTAside = _resolveComponent("KTAside")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_KTToolbar = _resolveComponent("KTToolbar")!
  const _component_el_affix = _resolveComponent("el-affix")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTFooter = _resolveComponent("KTFooter")!
  const _component_KTSidebar = _resolveComponent("KTSidebar")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!
  const _component_KTMenuModal = _resolveComponent("KTMenuModal")!
  const _component_KTSearchModal = _resolveComponent("KTSearchModal")!
  const _component_KTCreateApp = _resolveComponent("KTCreateApp")!
  const _component_KTPurchaseDrawer = _resolveComponent("KTPurchaseDrawer")!
  const _component_KTDrawerMessenger = _resolveComponent("KTDrawerMessenger")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.displayAside)
        ? (_openBlock(), _createBlock(_component_KTAside, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_affix, {
          offset: 0,
          style: {"z-index":"1 !important"}
        }, {
          default: _withCtx(() => [
            (_ctx.displayHeader)
              ? (_openBlock(), _createBlock(_component_KTHeader, {
                  key: 0,
                  title: _ctx.pageTitle
                }, null, 8, ["title"]))
              : _createCommentVNode("", true),
            (_ctx.displayToolbar)
              ? (_openBlock(), _createBlock(_component_KTToolbar, {
                  key: 1,
                  breadcrumbs: _ctx.breadcrumbs,
                  title: _ctx.pageTitle,
                  section: _ctx.pageSection
                }, null, 8, ["breadcrumbs", "title", "section"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass({
              'container-fluid': _ctx.contentFluid,
              container: !_ctx.contentFluid,
            })
            }, [
              _createVNode(_component_router_view)
            ], 2)
          ])
        ]),
        _createVNode(_component_KTFooter)
      ])
    ]),
    (_ctx.displaySidebar)
      ? (_openBlock(), _createBlock(_component_KTSidebar, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.displayScrollTop)
      ? (_openBlock(), _createBlock(_component_KTScrollTop, { key: 2 }))
      : _createCommentVNode("", true),
    _createVNode(_component_KTMenuModal),
    _createVNode(_component_KTSearchModal),
    _createVNode(_component_KTCreateApp),
    false
      ? (_openBlock(), _createBlock(_component_KTPurchaseDrawer, { key: 3 }))
      : _createCommentVNode("", true),
    _createVNode(_component_KTDrawerMessenger)
  ], 64))
}