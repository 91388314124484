
import { defineComponent } from "vue";

export default defineComponent({
  name: "widget-1",
  setup() {
    const list = [
      {
        name: "Brad Simmons",
        uses: "HTML/CSS/JS, Python",
        svg: "media/svg/avatars/009-boy-4.svg",
        bg: "primary",
        paid: "$2,000,000",
      },
      {
        name: "Jessie Clarcson",
        uses: "HTML, ReactJS, ASP.NET",
        svg: "media/svg/avatars/006-girl-3.svg",
        bg: "danger",
        paid: "$1,200,000",
      },
      {
        name: "Lebron Wayde",
        uses: "HTML. Laravel Framework",
        svg: "media/svg/avatars/011-boy-5.svg",
        bg: "success",
        paid: "$3,400,000",
      },
      {
        name: "Kevin Leonard",
        uses: "VueJS, Laravel Framework",
        svg: "media/svg/avatars/015-boy-6.svg",
        bg: "warning",
        paid: "$35,600,000",
      },
    ];

    return { list };
  },
});
