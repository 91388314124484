
import { defineComponent } from "vue";
import { Actions } from "@/store/enums/store.enums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { listProfileFeatures } from "@/graphql/queries";
import { Auth, API, graphqlOperation } from "aws-amplify";

export default defineComponent({
  name: "dropdown-1",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    const signOut = () => {
      store.dispatch(Actions.LOGOUT).then(() => router.push({ name: "login" }));
    };

    return {
      signOut,
    };
  },
  mounted() {
    setTimeout(() => {
      this.loadProfile();
    }, 3000);
  },
  data() {
    return {
      profile: {
        id: "",
        username: "",
        avatar: "",
        name: "",
        birthday: "",
        age: 0,
        gender: "",
        verified: false,
        geolocation: null,
        metadata: "",
        enabled: true,
        auth_signup_id: null,
      },
    };
  },
  methods: {
    async loadProfile() {
      try {
        var username = null;
        await Auth.currentAuthenticatedUser().then((user) => {
          username = user.username;
        });

        if (username) {
          const response: any = await API.graphql(
            graphqlOperation(listProfileFeatures, {
              filter: {
                auth_signup_id: {
                  eq: username,
                },
                enabled: {
                  eq: true,
                },
              },
            })
          );

          if (response.data.listProfileFeatures.items.length > 0) {
            this.profile = response.data.listProfileFeatures.items[0];
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
