
import { defineComponent } from "vue";

export default defineComponent({
  name: "upgrade-to-pro",
  components: {},
  setup() {
    const themeFullName = "POA.city Store";
    const relatedProductsTitle = "Recomendações";
    const relatedProductsUrl = "/@poa.city";
    const relatedProductsUrlTitle = "Ver mais";
    const relatedProductsActionButton = "Produtos Personalizados";
    const paymentStoreActionButton = "Pagar com PIX";

    const relatedProducts = [
      {
        name: "Start HTML Pro",
        description: "HTML5, CSS3, JS, Bootstrap 5",
        link: "https://keenthemes.com/products/start-html-pro-admin-dashboard-template",
        type: "pro",
        typeText: "pro",
        logo: "https://images.unsplash.com/photo-1673878034060-2d97a101563a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=388&q=80",
      },
      {
        name: "Start Vue Pro",
        description: "VueJS 3, Typescript, Bootstrap 5",
        link: "https://keenthemes.com/products/start-vue-pro-admin-dashboard-template",
        type: "free",
        typeText: "free",
        logo: "https://images.unsplash.com/photo-1673803060102-c8e72de29a89?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      },
      {
        name: "Start React Pro",
        description: "React, Typescript, Bootstrap 5",
        link: "https://keenthemes.com/products/start-react-pro-admin-dashboard-template",
        type: "",
        typeText: "",
        logo: "https://images.unsplash.com/photo-1673833453112-8bf23d68ccd0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      },
    ];

    return {
      relatedProductsTitle,
      relatedProductsUrl,
      relatedProductsUrlTitle,
      relatedProductsActionButton,
      paymentStoreActionButton,
      relatedProducts,
      themeFullName,
    };
  },
});
