
import { defineComponent, computed, watch, ref } from "vue";
import { useRoute } from "vue-router";
import { toolbarWidthFluid } from "@/core/helpers/config";
import headerMenuConfig from "@/core/config/MainMenuConfig";
import { Auth } from "aws-amplify";

export default defineComponent({
  name: "Toolbar",
  props: {
    breadcrumbs: Object,
    title: String,
    section: String,
  },
  setup(props) {
    const route = useRoute();

    const pageSection = ref(props.section);
    const dataReceived = ref(false);

    let userDisplayName = ref("");

    Auth.currentAuthenticatedUser().then((user) => {
      userDisplayName.value = user.attributes.given_name;
    });

    watch(props, (from, to) => {
      if (typeof to.section !== "undefined") {
        pageSection.value = props.section;
        dataReceived.value = true;
      } else {
        dataReceived.value = false;
      }
    });

    const getSectionArray = computed(() => {
      return headerMenuConfig.find((item) => {
        return item.heading === pageSection.value;
      });
    });

    const displayLinks = computed(() => {
      if (!route.meta.asideEnabled) {
        return !((route.path as string).indexOf("builder") !== -1);
      }
      return false;
    });

    const getActiveLink = computed(() => {
      return route.path;
    });

    const displaySectionMenu = () => {
      return !route.meta.asideEnabled;
    };

    const asideEnabled = computed(() => {
      return route.meta.asideEnabled;
    });

    const desc = computed(() => {
      return route.meta.desc;
    });

    return {
      toolbarWidthFluid,
      asideEnabled,
      displaySectionMenu,
      headerMenuConfig,
      getSectionArray,
      dataReceived,
      getActiveLink,
      displayLinks,
      desc,
      userDisplayName,
    };
  },
});
