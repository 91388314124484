const MainMenuConfig = [
  {
    heading: "Caldeira Cloud",
    pages: [
      {
        heading: "Inicio",
        route: "/home",
      },
      {
        heading: "Planejar",
        route: "/business",
      },
      {
        heading: "Implementar",
        route: "/ai/implement",
      },
      {
        heading: "Consultar",
        route: "/ai/consult",
      },
    ],
  },
  {
    heading: "Marketplace",
    pages: [
      // {
      //   heading: "Private Chat",
      //   route: "/apps/chat/private-chat",
      // },
      // {
      //   heading: "Group Chat",
      //   route: "/apps/chat/group-chat",
      // },
      // {
      //   heading: "Drawer Chat",
      //   route: "/apps/chat/drawer-chat",
      // },
      // {
      //   heading: "Shop 1",
      //   route: "/shop1",
      // },
      // {
      //   heading: "Shop 2",
      //   route: "/shop2",
      // },
      // {
      //   heading: "Shop Product",
      //   route: "/product",
      // },
    ],
  },
  {
    heading: "Parceiros",
    pages: [
      // {
      //   heading: "Seja Membro",
      //   route: "/sejamembro",
      // },
      {
        heading: "FAQ",
        route: "/faq",
      },
      // {
      //   heading: "Planos",
      //   route: "/pricing",
      // },
      // {
      //   heading: "Invoice",
      //   route: "/invoice",
      // },
      {
        heading: "Já Sou Membro",
        route: "/login",
      }
      // {
      //   heading: "Error",
      //   route: "/error",
      // },
    ],
  },
  {
    heading: "Conta",
    pages: [
      // {
      //   heading: "Overview",
      //   route: "/overview",
      // },
      // {
      //   heading: "Conta",
      //   route: "/conta",
      // },
      {
        heading: "Entrar Business",
        route: "/business",
      },
      // {
      //   heading: "Settings",
      //   route: "/settings",
      // },
    ],
  },
  {
    heading: "Business",
    pages: [
      // {
      //   heading: "Overview",
      //   route: "/overview",
      // },
      // {
      //   heading: "Conta",
      //   route: "/conta",
      // },
      // {
      //   heading: "Business",
      //   route: "/business",
      // },
      // {
      //   heading: "Settings",
      //   route: "/settings",
      // },
    ],
  },
  {
    heading: "Resources",
    pages: [
      {
        heading: "Documentation",
        route: "/documentation/doc-overview",
      },
      {
        heading: "Layout builder",
        route: "/builder",
      },
      {
        heading: "Changelog",
        route: "/documentation/changelog",
      },
    ],
  },
];

export default MainMenuConfig
