
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Sidebar",
  components: {},
  setup() {
    const route = useRoute();

    const sidebarType = () => {
      return route.meta.sidebar
        ? "kt-sidebar-" + route.meta.sidebar
        : "kt-sidebar-general";
    };

    const isShop = () => {
      return route.meta.sidebar === "shop";
    };

    return {
      sidebarType,
      isShop,
    };
  },
});
