
import { defineComponent, ref } from "vue";
import KTMenu from "@/view/layout/header/Menu.vue";
import KTBrand from "@/view/layout/brand/Brand.vue";
import menuModalData from "@/data/menu-modal-collection.json";
import { Auth } from "aws-amplify";
import { Actions } from "@/store/enums/store.enums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "MenuModal",
  components: {
    KTMenu,
    KTBrand,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const signOut = () => {
      modalShow.value = !modalShow.value;
      store.dispatch(Actions.LOGOUT).then(() => router.push({ name: "login" }));
    };

    const modalShow = ref<boolean>(false);
    const toggleModal = () => {
      modalShow.value = !modalShow.value;
    };

    const menuModalCollection = menuModalData;

    let userDisplayName = ref("");
    let myAccountBtnLabelText = ref("Minha Conta");
    let routepath = ref("/conta");
    let user = ref({});
    let isAuthenticated = ref<boolean>(false);

    Auth.currentAuthenticatedUser()
      .then((user) => {
        userDisplayName.value = "Olá, " + user.attributes.name;
        user.value = user;
        isAuthenticated.value = !isAuthenticated.value;
      })
      .catch((err) => {
        console.log(err);
      });

    if (!user.value) {
      myAccountBtnLabelText = ref("Entrar");
      routepath = ref("/login");
    }

    const goToAction = () => {
      setTimeout(() => {
        router.push(routepath.value);
        modalShow.value = !modalShow.value;
      }, 1000);
    };

    return {
      isAuthenticated,
      user,
      signOut,
      modalShow,
      toggleModal,
      menuModalCollection,
      userDisplayName,
      myAccountBtnLabelText,
      goToAction,
    };
  },
});
