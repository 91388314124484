import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, { to: "/" }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: _ctx.siteLogo(),
        class: "h-150px",
        alt: "Caldeira Cloud"
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }))
}