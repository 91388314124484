
import { computed, defineComponent, onMounted, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import {
  config,
  displaySidebar,
  displayAside,
  loaderEnabled,
  contentFluid,
  displayScrollTop,
  displayHeader,
  displayToolbar,
} from "@/core/helpers/config";
import { loading } from "@/core/helpers/loader";
import { Actions } from "@/store/enums/store.enums";
import LayoutService from "@/core/services/LayoutService";
import KTSidebar from "@/view/layout/sidebar/Sidebar.vue";
import KTLoader from "@/view/content/Loader.vue";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop.vue";
import KTMenuModal from "@/view/layout/extras/MenuModal.vue";
import KTSearchModal from "@/view/layout/extras/SearchModal.vue";
import KTToolbar from "@/view/layout/toolbar/Toolbar.vue";
import KTCreateApp from "@/view/content/modals/CreateAppModal.vue";
import KTPurchaseDrawer from "@/view/layout/extras/PurchaseDrawer.vue";
import KTDrawerMessenger from "@/view/layout/extras/DrawerMessenger.vue";
import { MenuComponent } from "@/assets/ts/components";
import { reinitializeComponents } from "@/core/plugins/keenthemes";
import { Auth } from "aws-amplify";

export default defineComponent({
  name: "layout",
  components: {
    KTAside,
    KTSidebar,
    KTLoader,
    KTHeader,
    KTFooter,
    KTScrollTop,
    KTMenuModal,
    KTSearchModal,
    KTToolbar,
    KTCreateApp,
    KTPurchaseDrawer,
    KTDrawerMessenger,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const breadcrumbs = computed(() => {
      return store.getters.getBreadcrumbs;
    });

    const pageSection = computed(() => {
      return store.getters.pageSection;
    });

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const isDocPage = computed(() => {
      return route.path.indexOf("/documentation") !== -1;
    });

    const checkAuth = () => {
      // check if current user is authenticated
      if (!isDocPage.value) {
        Auth.currentAuthenticatedUser()
          .then(() => {})
          .catch((err) => {
            console.log(err);
            router.push({ name: "login" });
          });
      }
    };

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    onMounted(() => {
      // checkAuth();

      LayoutService.initCurrentRouteLayout();

      nextTick(() => {
        reinitializeComponents();
      });

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 1000);
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);
        LayoutService.initCurrentRouteLayout();

        nextTick(() => {
          reinitializeComponents();
        });

        // checkAuth();
      }
    );

    watch(
      () => route.meta.asideEnabled,
      (from, to) => {
        if (!to) {
          loading(500);
        }
      }
    );

    return {
      breadcrumbs,
      pageTitle,
      displaySidebar,
      displayAside,
      loaderEnabled,
      config,
      contentFluid,
      displayScrollTop,
      displayHeader,
      displayToolbar,
      pageSection,
      store,
    };
  },
});
