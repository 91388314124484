
import { defineComponent, onMounted } from "vue";
import { version } from "@/core/helpers/documentation";
import { headerWidthFluid, displayAsideSecondary } from "@/core/helpers/config";
import {
  headerType,
  asideEnabled,
  headerTypeWithMenu,
} from "@/core/helpers/header";
import Dropdown1 from "@/view/content/dropdown/Dropdown1.vue";
import Dropdown2 from "@/view/content/dropdown/Dropdown2.vue";
import KTBrand from "@/view/layout/brand/Brand.vue";
import { StickyComponent } from "@/assets/ts/components";
import MenuModal from "@/view/layout/extras/MenuModal.vue";

export default defineComponent({
  name: "Header",
  props: {
    title: String,
  },
  components: {
    Dropdown1,
    Dropdown2,
    KTBrand,
    MenuModal,
  },
  setup() {
    onMounted(() => {
      StickyComponent.bootstrap();
    });

    return {
      headerType,
      asideEnabled,
      headerWidthFluid,
      headerTypeWithMenu,
      version,
      displayAsideSecondary,
    };
  },
});
