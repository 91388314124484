
import { defineComponent } from "vue";
import {
  displayAsidePrimary,
  displayAsideSecondary,
  displayToggleButton,
  minimizedByDefault,
} from "@/core/helpers/config";
import { asideMenu } from "@/core/helpers/aside";
import KTAsideMenu from "@/view/layout/aside/Menu.vue";
import Dropdown3 from "@/view/content/dropdown/Dropdown3.vue";

export default defineComponent({
  name: "kt-aside",
  components: {
    KTAsideMenu,
    Dropdown3,
  },
  setup() {
    return {
      asideMenu,
      displayAsidePrimary,
      displayAsideSecondary,
      displayToggleButton,
      minimizedByDefault,
    };
  },
});
