
import { defineComponent } from "vue";
/*
TIP: To start with clean menu update the config path to @/core/config/MainMenuCleanConfig".
 */
import menuConfig from "@/core/config/MainMenuConfig";

export default defineComponent({
  name: "kt-menu",
  emit: ["toggleModal"],
  components: {},
  props: {},
  setup() {
    return {
      menuConfig,
    };
  },
});
