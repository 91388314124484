
import { defineComponent, computed } from "vue";
import { loaderType } from "@/core/helpers/config";

export default defineComponent({
  name: "Loader",
  setup() {
    const loader = loaderType.value;

    const displayDefault = computed(() => {
      return loader === "default";
    });

    const displaySpinnerMessage = computed(() => {
      return loader === "spinner-message";
    });

    const displaySpinnerLogo = computed(() => {
      return loader === "spinner-logo";
    });

    return {
      displayDefault,
      displaySpinnerMessage,
      displaySpinnerLogo,
    };
  },
});
